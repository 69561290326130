<template>
  <div>
    <AppTitle titulo="Usuário" />

    <section class="section">
      <div class="container is-fullhd">
        <div class="columns">
          <div class="column is-narrow">
            <SecondaryMenu titulo="Perfil" :itens="menu" />
          </div>
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppTitulo from '@/components/AppTitulo';
import MenuSecundario from '@/components/MenuSecundario';

export default {
  name: 'Profile',
  components: {
    AppTitle: AppTitulo,
    SecondaryMenu: MenuSecundario,
  },
  computed: {
    ...mapGetters({
      usuario: 'getUser',
    }),
    menu() {
      return [
        { titulo: 'Dados Cadastrais', rota: 'ProfileData', parametros: { id: this.usuario.id } },
        { titulo: 'Alterar Senha', rota: 'ChangePassword', parametros: { id: this.usuario.id } },
        { titulo: 'Alterar PIN', rota: 'ChangePin', parametros: { id: this.usuario.id } },
      ];
    },
  },
};
</script>
